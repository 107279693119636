import React from 'react';

const VeganIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    fill="none"
    viewBox="0 0 44 44"
    {...props}
  >
    <path
      fill="#091F40"
      d="M23.882 27.278a.687.687 0 01-1.195-.464v-4.842c-.228.014-.457.03-.687.03-.23 0-.457-.01-.688-.025v4.837a.687.687 0 01-1.194.464l-5.856-6.387a6.822 6.822 0 01-1.306.938l4.737 5.21c.116.125.18.29.182.462v13.75h2.75v-2.75H22v2.75h1.375v-4.125h1.375v4.125h1.375v-13.75c0-.172.063-.337.178-.462l4.738-5.21a6.822 6.822 0 01-1.307-.938l-5.852 6.387z"
    ></path>
    <path
      fill="#091F40"
      d="M34.375 10.314a5.437 5.437 0 00-2.539.624.685.685 0 01-.998-.512 8.926 8.926 0 00-17.675 0 .685.685 0 01-.999.512 5.506 5.506 0 00-7.358 2.212 5.501 5.501 0 109.77 5.046.686.686 0 011.104-.187 8.853 8.853 0 005.041 2.527c.425.06.851.09 1.28.09a8.875 8.875 0 006.317-2.617.696.696 0 01.603-.19c.22.036.408.178.504.378a5.5 5.5 0 104.95-7.883zm-26.125 5.5H6.875v1.375H5.5v-1.375h1.375V14.44H8.25v1.375zm2.063 2.75H8.937V17.19h1.376v1.375z"
    ></path>
  </svg>
);

export default VeganIcon;

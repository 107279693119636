import React from 'react';
import RoundButtonBgAlto from '../../images/round-button-bg-alto.svg';
import RoundButtonBgLinen from '../../images/round-button-bg-linen.svg';
import RoundButtonBg from '../../images/round-button-bg.svg';
import ArrowLeftIcon from '../atoms/ArrowLeftIcon';
import ArrowRightIcon from '../atoms/ArrowRightIcon';

interface ArrowButtonProps {
  className?: string;
  id?: string;
  ariaLabel?: string;
  variant: 'left' | 'right' | 'down';
  color?: 'white' | 'linen' | 'alto';
  onClick: () => void;
}

const colorMap = {
  white: RoundButtonBg,
  linen: RoundButtonBgLinen,
  alto: RoundButtonBgAlto,
};

const ArrowButton = ({
  variant,
  className = '',
  id,
  ariaLabel,
  color = 'white',
  onClick,
}: ArrowButtonProps) => (
  <button
    type="button"
    className={`flex items-center justify-center w-12 h-12 bg-center bg-no-repeat bg-contain rounded-full focus:outline-none ${className}`}
    id={id}
    aria-label={ariaLabel}
    style={{
      backgroundImage: `url(${colorMap[color]})`,
    }}
    onClick={onClick}
  >
    {variant === 'left' && <ArrowLeftIcon className="animate-slide1-left" />}
    {variant === 'right' && <ArrowRightIcon className="animate-slide1-right" />}
    {variant === 'down' && <ArrowRightIcon className="transform rotate-90" />}
  </button>
);

export default ArrowButton;

import React from 'react';

const GlutenFreeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    fill="none"
    viewBox="0 0 44 44"
    {...props}
  >
    <path
      fill="#091F40"
      d="M23.657 40.66a.543.543 0 01-.476-.32 17.361 17.361 0 01-1.247-5.42.544.544 0 011.03-.07 16.282 16.282 0 001.172 5.111.517.517 0 01-.005.394.52.52 0 01-.283.273.447.447 0 01-.19.032zM19.334 27.641l-5.649-2.845a.155.155 0 00-.144.03.15.15 0 00-.046.139l1.782 6.348c.51 2.38 3.746 3.566 5.55 1.935 1.808-1.632.773-4.5-1.493-5.607zM23.323 17.526c1.084 2.168 4.548 2.168 5.893.119l3.252-5.036h-.002a.146.146 0 00.013-.144.148.148 0 00-.121-.082l-5.598.364c-2.548.15-4.521 2.61-3.437 4.779zM22.804 11.845c2.109 1.213 4.878-.542 4.878-2.975V3.644a.149.149 0 00-.074-.125.141.141 0 00-.142 0L22.94 6.126c-2.108 1.22-2.244 4.5-.135 5.719zM31.215 24.984l-3.936 1.821 2.63 3.062 1.484-4.712.002.003a.146.146 0 00-.038-.142.14.14 0 00-.142-.032zM31.573 18.704l-5.768 1.209a4.282 4.282 0 00-2.76 1.918l3.73 4.337a3.516 3.516 0 002.168-1.779l2.78-5.514.002.002a.147.147 0 00-.032-.127.142.142 0 00-.12-.046zM14.954 12.422l4.635 5.421a2.935 2.935 0 002.168-.99c1.584-1.85.271-4.727-2.168-5.48l-5.361-1.73a.149.149 0 00-.136.048.156.156 0 00-.027.142l.889 2.59z"
    ></path>
    <path
      fill="#091F40"
      d="M34.884 36.968l-16.64-19.346-2.543-2.96v.032l-5.345-6.238a.77.77 0 00-1.17 1.003l8.196 9.529-4.174-1.317a.15.15 0 00-.135.05.14.14 0 00-.027.14l2.233 5.757c.824 2.294 4.18 3.138 5.751 1.28.248-.304.432-.65.542-1.026l3.437 3.996c-1.99 1.177-2.853 3.746-1.22 5.317 1.48 1.425 4.038.878 5.15-.748l4.802 5.54a.769.769 0 001.086.078.769.769 0 00.078-1.086h-.02z"
    ></path>
  </svg>
);

export default GlutenFreeIcon;

import React from 'react';
import MenuCategory from '../molecules/MenuCategory';

interface MenuCategoriesSectionProps {
  menus: Array<{ title: string; orderNowUrl: string; items: Array<any> }>;
}

const MenuCategoriesSection = ({ menus }: MenuCategoriesSectionProps) => (
  <div className="pb-12">
    {menus?.map((menu, index) => (
      <MenuCategory
        key={menu.title}
        index={index}
        title={menu.title}
        orderNowUrl={menu.orderNowUrl}
        items={menu.items}
        isLinen={index % 2 === 1}
      />
    ))}
  </div>
);

export default MenuCategoriesSection;

import { motion } from 'framer-motion';
import { getImage } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';
import { useKey, useLockBodyScroll } from 'react-use';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseButtonBg from '../../images/close-btn-bg.svg';
import WaveWhite from '../../images/wave-white-inverted.svg';
import BurgerClose from '../atoms/BurgerClose';
import Button from '../atoms/Button';
import GlutenFreeIcon from '../atoms/GlutenFreeIcon';
import Image from '../atoms/Image';
import VeganIcon from '../atoms/VeganIcon';
import ArrowButton from './ArrowButton';

interface MenuItemPopupProps {
  item: {
    title: string;
    saltyMenuItem: {
      description: string;
      glutenFree: boolean;
      vegan: boolean;
      orderNowUrl: string;
      gallery: Array<{ altText: string; localFile: any }>;
    };
  };
  onClose: () => void;
}

const MenuItemPopup = ({ item, onClose }: MenuItemPopupProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [photoIndex, setPhotoIndex] = useState(0);

  useLockBodyScroll();
  useKey('Escape', onClose);
  useOnClickOutside(ref, () => onClose());

  const prev = () => {
    setPhotoIndex(
      photoIndex - 1 < 0
        ? item.saltyMenuItem.gallery.length - 1
        : photoIndex - 1
    );
  };

  const next = () => {
    setPhotoIndex(
      photoIndex + 1 > item.saltyMenuItem.gallery.length - 1
        ? 0
        : photoIndex + 1
    );
  };

  return (
    <motion.div
      className="fixed inset-0 z-[70] flex flex-col items-center justify-start p-4 md:justify-center md:p-16 bg-linen bg-opacity-90"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="max-w-full w-[1024px]"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0, transition: { type: 'ease', delay: 0.5 } }}
        exit={{ opacity: 0, y: 0 }}
        ref={ref}
      >
        <div className="relative p-4 bg-white rounded-tl-lg rounded-tr-lg md:p-16 text-navy-blue">
          <button
            type="button"
            className="absolute flex items-center justify-center w-12 h-12 bg-center bg-no-repeat top-6 right-6 bg-container group"
            style={{ backgroundImage: `url(${CloseButtonBg})` }}
            onClick={onClose}
          >
            <BurgerClose className="w-6 h-6 transition-all origin-center group-hover:scale-110" />
          </button>
          <div className="grid grid-cols-1 gap-4 md:gap-16 md:grid-cols-2">
            <div className="flex items-center h-full">
              <div className="py-4 pr-16 md:py-20 md:pr-0">
                <h2 className="mb-4 u-h3">{item.title}</h2>
                <p className="mb-4 md:mb-8">{item.saltyMenuItem.description}</p>
                {(item.saltyMenuItem.glutenFree ||
                  item.saltyMenuItem.vegan) && (
                  <div className="flex mb-4 border-t md:mb-6 md:block border-faux-light">
                    {item.saltyMenuItem.glutenFree && (
                      <div className="flex items-center w-1/2 py-4 text-lg border-b md:text-xl md:w-full border-faux-light">
                        <GlutenFreeIcon className="w-auto h-8 mr-2 md:h-11" />
                        Gluten Free
                      </div>
                    )}
                    {item.saltyMenuItem.vegan && (
                      <div className="flex items-center w-1/2 py-4 pl-2 text-lg border-b md:pl-0 md:text-xl md:w-full border-faux-light">
                        <VeganIcon className="w-auto h-8 mr-2 md:h-11" />
                        Vegan
                      </div>
                    )}
                  </div>
                )}
                {item.saltyMenuItem.orderNowUrl && (
                  <Button to={item.saltyMenuItem.orderNowUrl} variant="default">
                    Order now
                  </Button>
                )}
              </div>
            </div>
            {item.saltyMenuItem.gallery?.length > 0 && (
              <div className="px-8 py-4 md:px-0">
                <Image
                  image={getImage(
                    item.saltyMenuItem.gallery[photoIndex].localFile
                  )}
                  alt={item.saltyMenuItem.gallery[photoIndex].altText}
                  className="w-full h-auto aspect-square"
                />
                {item.saltyMenuItem.gallery?.length > 1 && (
                  <div className="flex items-center justify-center space-x-6 text-navy-blue">
                    <ArrowButton
                      variant="left"
                      color="linen"
                      id="prev-gallery-btn"
                      ariaLabel="Previous image"
                      onClick={prev}
                    />
                    <div className="min-w-[32px] text-center">
                      {photoIndex + 1}/{item.saltyMenuItem.gallery.length}
                    </div>
                    <ArrowButton
                      variant="right"
                      color="linen"
                      id="next-gallery-btn"
                      ariaLabel="Next image"
                      onClick={next}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <motion.div
          className="w-full h-8 bg-top bg-repeat-x"
          style={{
            backgroundImage: `url(${WaveWhite})`,
            backgroundSize: '100% auto',
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default MenuItemPopup;

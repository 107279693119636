import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import MenuCategoriesSection from '../components/organisms/MenuCategoriesSection';
import MenuHeroSection from '../components/organisms/MenuHeroSection';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';

const MenuCity = ({
  data: {
    page,
    cities,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <MenuHeroSection
          name={`${page.title} Menu`}
          slug={page.slug}
          cities={cities.nodes}
        />
        <MenuCategoriesSection menus={page.city.menus} />

        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query MenuCityPageQuery($id: String!) {
    page: wpCity(id: { eq: $id }) {
      title
      ...CitySEO
      slug
      city {
        menus {
          title
          orderNowUrl
          items {
            ... on WpSaltyMenuItem {
              id
              title
              saltyMenuItem {
                description
                glutenFree
                vegan
                orderNowUrl
                photo {
                  localFile {
                    url
                    childImageSharp {
                      gatsbyImageData(width: 512, placeholder: BLURRED)
                    }
                  }
                }
                gallery {
                  altText
                  localFile {
                    url
                    childImageSharp {
                      gatsbyImageData(width: 512, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    cities: allWpCity {
      nodes {
        title
        slug
        city {
          showMenu
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default MenuCity;
